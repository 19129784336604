import { defineCustomElements as deckDeckGoHighlightElement } from "@deckdeckgo/highlight-code/dist/loader"
import { graphql } from "gatsby"
import { MDXRenderer } from "gatsby-plugin-mdx"
import gsap from "gsap"
import ScrollTrigger from "gsap/ScrollTrigger"
import hljs from "highlight.js/lib/core"
import javascript from "highlight.js/lib/languages/javascript"
import React, { useEffect, useRef, useState } from "react"
import styled from "styled-components"
import { BoxButton } from "../components/Button/BoxButton"
import Container from "@components/Container"
import Layout from "@components/layout"
import RelatedArticles from "@components/Post/RelatedArticles"
import Seo from "@components/seo"
gsap.registerPlugin(ScrollTrigger)
hljs.registerLanguage("javascript", javascript)
deckDeckGoHighlightElement()

const PostLayout = styled.div`
  display: grid;
  gap: var(--gap);
  width: 100%;
  grid-template:
    [row1-start] "post" [row1-end]
    [row2-start] "related" [row2-end];
  grid-template-columns: minmax(300px, 1fr);
  padding-bottom: calc(5rem * var(--multiplier));
  @media (min-width: 768px) {
    grid-template: [row1-start] "post related" [row1-end];
    grid-template-columns: minmax(300px, 3fr) minmax(300px, 1fr);
  }
`

const PostWrapper = styled.div`
  grid-area: post;
  .steam-deck-status {
    margin-bottom: 1rem;
  }
`
const RelatedArticlesWrapper = styled.aside`
  grid-area: related;
  &.mobile {
    position: fixed;
    display: block;
    inset: 7rem 1rem;
    @media (min-width: 768px) {
      display: none;
    }
  }
  &.desktop {
    display: none;
    @media (min-width: 768px) {
      position: relative;
      display: block;
    }
  }
`

const PostTitle = styled.h1`
  text-align: left;
  padding-bottom: calc(2rem * var(--multiplier));
  @media (min-width: 768px) {
    font-size: 4rem;
    text-align: center;
  }
  @media (min-width: 1200px) {
    font-size: 5.55rem;
  }
`

const RelatedArticesToggle = styled(BoxButton)`
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  @media (min-width: 768px) {
    display: none;
  }
`

const Post = ({ data }) => {
  const post = data.mdx
  const postCategory = data.mdx.frontmatter.contentType
  const description = data.mdx.frontmatter.metaDesc
  const postId = post.id
  const articlesWithSameCategory = data.allPosts.nodes.filter(
    article =>
      article.frontmatter.contentType === postCategory && article.id !== postId
  )
  const articlesWithDifferentCategory = data.allPosts.nodes.filter(
    article => article.frontmatter.contentType !== postCategory
  )
  const asideRef = useRef()
  const asideRefMobile = useRef()
  const seoImage = post.frontmatter.image.childImageSharp.fluid.src
  const [isAsideActive, setIsAsideActive] = useState(false)
  const mobileToggleButtonText = isAsideActive
    ? "Hide related articles"
    : "Show the related stuff"

  useEffect(() => {
    hljs.initHighlightingOnLoad()
  }, [])

  useEffect(() => {
    if (isAsideActive) {
      gsap.to(asideRefMobile.current, {
        duration: 0.35,
        xPercent: "0",
        ease: "back.out(1)",
      })
      return
    }
    gsap.to(asideRefMobile.current, {
      duration: 0.35,
      xPercent: "110",
    })
  }, [isAsideActive])

  useEffect(() => {
    gsap.from(asideRef.current, {
      duration: 0.5,
      delay: 1,
      autoAlpha: 0,
      x: "20px",
    })
  }, [asideRef])

  let relatedArticleContent = articlesWithSameCategory.map(article => article)

  if (relatedArticleContent.length < 5) {
    articlesWithDifferentCategory.forEach(article => {
      if (article.id !== postId && relatedArticleContent.length <= 4) {
        relatedArticleContent.push(article)
      }
    })
  }

  return (
    <Layout>
      <Seo
        title={post.frontmatter.title}
        description={description}
        article={true}
        image={seoImage}
      />
      <Container>
        <PostTitle>{post.frontmatter.title}</PostTitle>
        <PostLayout>
          <PostWrapper as="article">
            <MDXRenderer components={{ button: BoxButton }}>
              {post.body}
            </MDXRenderer>
          </PostWrapper>
          <RelatedArticlesWrapper className="mobile" ref={asideRefMobile}>
            <RelatedArticles articles={relatedArticleContent}></RelatedArticles>
          </RelatedArticlesWrapper>
          <RelatedArticlesWrapper className="desktop" ref={asideRef}>
            <RelatedArticles articles={relatedArticleContent}></RelatedArticles>
          </RelatedArticlesWrapper>
        </PostLayout>
        <RelatedArticesToggle onClick={() => setIsAsideActive(!isAsideActive)}>
          {mobileToggleButtonText}
        </RelatedArticesToggle>
      </Container>
    </Layout>
  )
}

export const query = graphql`
  query($slug: String!) {
    mdx(fields: { slug: { eq: $slug } }) {
      body
      id
      excerpt(pruneLength: 155, truncate: true)
      frontmatter {
        title
        keywords
        contentType
        image {
          childImageSharp {
            fluid {
              src
            }
          }
        }
      }
    }
    allPosts: allMdx {
      nodes {
        ...allPosts
      }
    }
  }
`

export default Post
