import React from 'react';
import styled from 'styled-components';
import { CardBase } from '../Cards/CardBase';
import { Link } from 'gatsby';


const ArticleListWrapper = styled(CardBase)`
    display: block;
    height: auto;
    &:hover {
        animation-name: none;
    }
`

const ArticleList = styled.ul`
    list-style: none;
    margin: 0;
    li:last-child a {
        border-bottom: 0;
    }
`

const ArticleListItem = styled.li`
    margin-bottom: 0;
    small {
        color: var(--color-accent);
    }
`

const ArticleLink = styled(Link)`
    border-bottom: 1px solid var(--color-accent);
    display: block;
    padding: calc(0.5rem * var(--multiplier)) 0;
    time {
    }
    h4 {
        margin-bottom: calc(0.25rem * var(--multiplier));
    }
`

const RelatedArticles = ({ articles }) => {
    return (
        <ArticleListWrapper as="section">
            <h3>Related stuff</h3>
            <ArticleList>
                {articles.map((article, i) => (
                <ArticleListItem key={i + article.frontmatter.title}>
                    <ArticleLink title={`Link to ${article.frontmatter.title}`} to={article.fields.slug}>
                            <h4>{article.frontmatter.title}</h4>
                            <small>Time to read: {article.timeToRead} minutes</small>
                    </ArticleLink>
                </ArticleListItem>
                ))}
            </ArticleList>
        </ArticleListWrapper>
    )
}

export default RelatedArticles;